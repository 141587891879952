import { createGlobalStyle } from 'styled-components/macro';
import { theme } from 'styled-tools';

const GlobalStyle = createGlobalStyle`
  html {
    body {
      background-color: ${theme('colors.secondary.light')};
    }
  }

  body {
    a {
      color: ${theme('colors.primary')};

      &:hover {
        color: ${theme('colors.primary')};
        text-decoration: underline;
      }
    }
  }
`;

export default GlobalStyle;
