import React from 'react';
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from 'utils/auth';
import {
  ROLE_USER,
  ROLE_PROPERTY_MANAGER,
  ROLE_RENTSAFE_ADMIN,
  ROLE_SUPER_ADMIN,
  userHasRole
} from 'utils/roles'

const propTypes = {
  role: PropTypes.oneOf([ROLE_USER, ROLE_PROPERTY_MANAGER, ROLE_RENTSAFE_ADMIN, ROLE_SUPER_ADMIN]),
}

function PrivateRoute({ role, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() && userHasRole(role) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = propTypes;

export default PrivateRoute;
