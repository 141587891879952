const colors = {
  primary: '#a0101e',
  secondary: {
    dark: '#16191e',
    medium: '#909090',
    regular: '#d7d2cc',
    light: '#f6f6f6',
  },
  white: '#fefefe',
  black: '#000000',
  disabledGray: '#b3b3b3',
  tableGray: '#dee2e6',
  danger: '#dc3545',
  success: '#28a745',
  warning: '#ffc107',
  green: '#00CA26',
};

export default {
  colors,
  fonts: {
    heading: 'Roboto, sans-serif',
    paragraph: 'Roboto, sans-serif',
    secondary: 'GT America Trial, sans-serif',
  },
  borderRadius: '0.25rem',
  boxShadow: `0px 0px 8px ${colors.secondary.medium}`,
};
