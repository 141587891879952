import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavItem,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { rem } from 'polished';
import { AuthContext } from 'contexts/authContext';
import logoImg from 'assets/images/rent-safe-logo.png';
import taglineImg from 'assets/images/rent-safe-tagline.png';

const UserNavDetails = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const StyledNavbar = styled(Navbar)`
  background: ${theme('colors.white')};
  box-shadow: ${theme('boxShadow')};
  padding: 0;
  height: 5rem;
`;

const StyledNav = styled(Nav)`
  margin-left: 1rem;
`;

const StyledNavItem = styled(NavItem)`
  height: 5rem;

  a {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 1rem;
    border-bottom: 3px solid ${theme('colors.white')};
  }

  .active {
    border-bottom: 3px solid ${theme('colors.primary')};
  }
`;

const Slogan = styled.img.attrs({
  src: taglineImg,
})`
  width: ${rem('300px')};
`;

const showApplicantDashboardMenu = () => {
  const { href } = window.location;
  return (
    href.indexOf('/dashboard') !== -1 || href.indexOf('/renters-profile') !== -1 || href.indexOf('/applications') !== -1
  );
};

const Navigation = () => {
  const { isAuthenticated } = React.useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <StyledNavbar light fixed={'top'} expand='md'>
      <NavbarBrand tag={Link} to='/'>
        <img src={logoImg} alt={'rentsafe logo'} />
      </NavbarBrand>
      <Slogan />
      <NavbarToggler onClick={toggle} />

      <Collapse isOpen={isOpen} navbar>
        <StyledNav className='mr-auto' navbar>
        </StyledNav>
        <Nav className='rightNav ml-auto' navbar>
          {isAuthenticated && (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <FontAwesomeIcon icon={faUserCircle} />
                <UserNavDetails>MY ACCOUNT</UserNavDetails>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <NavLink className='nav-link' to='/account' activeClassName='active'>
                    Edit Profile
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink className='nav-link' to='/logout'>
                    Logout
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </Nav>
      </Collapse>
    </StyledNavbar>
  );
};

export default Navigation;
