/** global: localStorage */
import jwtDecode from 'jwt-decode';

const USER_AUTH_TOKEN = 'rentsafe_auth_token';

export const getToken = () => localStorage.getItem(USER_AUTH_TOKEN);

export const setToken = token => localStorage.setItem(USER_AUTH_TOKEN, token);

export const removeToken = () => localStorage.removeItem(USER_AUTH_TOKEN);

export const getUserData = () => isAuthenticated() && jwtDecode(getToken());

export const isAuthenticated = () => !!getToken();

export const clearSessionStorage = () => {
  removeToken();
};
