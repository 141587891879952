import React from 'react';
import loadable from '@loadable/component';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import { ROLE_USER, ROLE_PROPERTY_MANAGER } from 'utils/roles';

const IndexPage = loadable(() => import('routes/Index'));
const LoginPage = loadable(() => import('routes/Login'));
const LogoutPage = loadable(() => import('routes/Logout'));
const PropertyManagerDashboard = loadable(() => import('routes/PropertyManagerDashboard'));
const UserAccountPage = loadable(() => import('routes/UserAccount'));
const ApplicantRegistration = loadable(() => import('routes/Registration/Applicant'));
const PropertyManagerRegistration = loadable(() => import('routes/Registration/PropertyManager'));
const TermsAndConditions = loadable(() => import('routes/TermsAndConditions'));
const RefundPolicy = loadable(() => import('routes/RefundPolicy'));
const NewProperty = loadable(() => import('routes/Properties/NewProperty'));
const EditProperty = loadable(() => import('routes/Properties/EditProperty'));
const Application = loadable(() => import('routes/Application'));
const ApplicantDashboard = loadable(() => import('routes/ApplicantDashboard'));

const Routes = () => (
  <Switch>
    <PrivateRoute path='/' component={IndexPage} exact role={ROLE_USER} />
    <Route path='/login' component={LoginPage} exact />
    <Route path='/logout' component={LogoutPage} exact />
    <PrivateRoute path='/account' component={UserAccountPage} exact role={ROLE_USER} />
    <PrivateRoute
      path='/property-manager-dashboard'
      component={PropertyManagerDashboard}
      exact
      role={ROLE_PROPERTY_MANAGER}
    />
    <Route path='/properties/new' component={NewProperty} exactrole={ROLE_PROPERTY_MANAGER} />
    {/* Temporary path, will need :id segment eventually */}
    <Route path='/properties/edit' component={EditProperty} exact role={ROLE_PROPERTY_MANAGER} />
    <Route path='/renter-registration' component={ApplicantRegistration} exact />
    <Route path='/property-manager-registration' component={PropertyManagerRegistration} exact />
    <Route path='/terms-and-conditions' component={TermsAndConditions} exact />
    <Route path='/refund-policy' component={RefundPolicy} exact />

    {/* Applicant Dashboard */}
    <PrivateRoute path='/applicant-dashboard' component={ApplicantDashboard} role={ROLE_USER} />

    {/* Property Manager Dashboard */}

    {/* Application Process */}
    {/* A user must log in or create an account to start the application process */}
    <Route
      path='/application/:code'
      render={({ history, match: { url } }) => <Application history={history} url={url} />}
    />
  </Switch>
);

export default Routes;
