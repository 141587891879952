import React from 'react';

export const MATCH_STATUS = {
  MATCH: 'match',
  NOT_REGISTERED: 'notify',
  NOT_LINKED: 'remind',
  NONE: false,
};

export const APPLICATION_STATUS = {
  APPROVED: 'approved',
  CONDITIONALLY_APPROVED: 'conditionally approved',
  REJECTED: 'rejected',
  NEEDS_UPDATES: 'needs updates',
  NEW: 'new',
  INCOMPLETE: 'incomplete',
  NEEDS_REVIEW: 'needs review',
};

export const RESETTING_EMAIL_CONFIRMATION_MESSAGE =
  'Reset password confirmation email was sent to: :EMAIL:';

export const RESETTING_RESET_COMPLETE_MESSAGE =
  'Your password has been reset. Please login now with your new password';

export const VALIDATE_PASSWORD_ERROR_MESSAGE = 'Invalid password.';

export const VALIDATE_EMAIL_NOT_FOUND_MESSAGE = 'This email does not exist.';

export const VALIDATE_CURRENT_PASSWORD_ERROR_MESSAGE =
  'Could not update password! Current password did not match.';

export const VALIDATE_LOGIN_GENERAL_ERROR = 'Sorry, something went wrong';

export const VALIDATE_EMAIL_NOT_VALID_MESSAGE = 'Please enter a valid email address.';

export const STATES_LIST = (
  <>
    <option value='AL'>Alabama</option>
    <option value='AK'>Alaska</option>
    <option value='AZ'>Arizona</option>
    <option value='AR'>Arkansas</option>
    <option value='AS'>American Samoa</option>
    <option value='CA'>California</option>
    <option value='CO'>Colorado</option>
    <option value='CT'>Connecticut</option>
    <option value='DE'>Delaware</option>
    <option value='DC'>District Of Columbia</option>
    <option value='FL'>Florida</option>
    <option value='GA'>Georgia</option>
    <option value='GU'>Guam</option>
    <option value='HI'>Hawaii</option>
    <option value='ID'>Idaho</option>
    <option value='IL'>Illinois</option>
    <option value='IN'>Indiana</option>
    <option value='IA'>Iowa</option>
    <option value='KS'>Kansas</option>
    <option value='KY'>Kentucky</option>
    <option value='LA'>Louisiana</option>
    <option value='ME'>Maine</option>
    <option value='MD'>Maryland</option>
    <option value='MA'>Massachusetts</option>
    <option value='MI'>Michigan</option>
    <option value='MN'>Minnesota</option>
    <option value='MS'>Mississippi</option>
    <option value='MO'>Missouri</option>
    <option value='MT'>Montana</option>
    <option value='NE'>Nebraska</option>
    <option value='NV'>Nevada</option>
    <option value='NH'>New Hampshire</option>
    <option value='NJ'>New Jersey</option>
    <option value='NM'>New Mexico</option>
    <option value='NY'>New York</option>
    <option value='NC'>North Carolina</option>
    <option value='ND'>North Dakota</option>
    <option value='MP'>Northern Mariana Islands</option>
    <option value='OH'>Ohio</option>
    <option value='OK'>Oklahoma</option>
    <option value='OR'>Oregon</option>
    <option value='PA'>Pennsylvania</option>
    <option value='PR'>Puerto Rico</option>
    <option value='RI'>Rhode Island</option>
    <option value='SC'>South Carolina</option>
    <option value='SD'>South Dakota</option>
    <option value='TN'>Tennessee</option>
    <option value='TX'>Texas</option>
    <option value='UT'>Utah</option>
    <option value='UM'>United States Minor Outlying Islands</option>
    <option value='VT'>Vermont</option>
    <option value='VA'>Virginia</option>
    <option value='VI'>Virgin Islands</option>
    <option value='WA'>Washington</option>
    <option value='WV'>West Virginia</option>
    <option value='WI'>Wisconsin</option>
    <option value='WY'>Wyoming</option>
  </>
);
export const SUPPORT_EMAIL = 'safesupport@rentsafe.lease';
