import React from 'react'
import { isAuthenticated } from 'utils/auth'
import { getToken } from '../utils/auth'

export const AuthContext = React.createContext()

export function AuthProvider({ children }) {
  const [isAuth, setIsAuth] = React.useState(isAuthenticated())
  const [toggle, setToggle] = React.useState();

  const flagChange = () => {
    setToggle(!toggle);
  }

  React.useEffect(() => {
    setIsAuth(!!getToken())
  }, [toggle])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuth,
        updateAuthStatus: flagChange,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const AuthConsumer = AuthContext.Consumer
