import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { rem } from 'polished';
import { Container } from 'reactstrap';
import { SUPPORT_EMAIL } from 'utils/consts';

const FooterWrapper = styled.footer`
  background: ${theme('colors.white')};
  height: 3.5rem;
  padding: 1rem 0;
  font-size: ${rem('14px')};
`;

const FooterContainer = styled(Container)`
  display: flex;
`;

const FooterLeft = styled.div`
  color: ${theme('colors.primary')};

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  li + li {
    margin-left: 1rem;

    &:before {
      content: '|';
      padding-right: 1rem;
    }
  }
`;

const FooterRight = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 1rem;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterLeft>
          <ul>
            <li>
              <a href='http://rentsafe.lease' rel='nofollow noreferrer'>
                About Us
              </a>
            </li>
            <li>
              <Link to='/terms-and-conditions'>Terms and Conditions</Link>
            </li>
            <li>
              <Link to='/refund-policy'>Refund Policy</Link>
            </li>
          </ul>
        </FooterLeft>
        <FooterRight>
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          <span>920 I ST NW #705, Washington DC, 20001</span>
        </FooterRight>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
