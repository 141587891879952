import React from 'react';
import Nav from './components/Nav';
import Routes from './routes';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import Footer from 'components/Footer';
import './styles/index.scss';

const SiteWrapper = styled.div`
  background: ${theme('colors.secondary.light')};
  min-height: 100%;
  margin-bottom: -3.5rem;
  padding-top: 5rem;
`;

const App = () => {
  return (
    <>
      <SiteWrapper>
        <Nav />
        <Routes />
      </SiteWrapper>
      <Footer />
    </>
  );
};

export default App;
