import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { AuthProvider } from 'contexts/authContext';
import App from './App';
import theme from './theme';
import GlobalStyle from './GlobalStyle';
import { isAuthenticated, getToken } from 'utils/auth';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  headers: {
    authorization: isAuthenticated() ? `Bearer ${getToken()}` : '',
  },
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: isAuthenticated() ? `Bearer ${getToken()}` : '',
    },
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const ReactApp = () => (
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <GlobalStyle />
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </AuthProvider>
  </ThemeProvider>
);

ReactDOM.render(<ReactApp />, document.getElementById('root'));
